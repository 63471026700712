import { computed, ref, toRaw } from 'vue'

import { PhonesType, PhonesTypeFile } from '@/api/__generated__/api.schema'
import type { SendFiltersForm } from '@/api/__generated__/api.schema'
import { i18n } from '@/i18n'
import { checkboxToBoolean } from '@/utils/checkbox'
import {
  linkHttpRegex,
  linkLinkRegex,
  originatorOptions,
} from '@/utils/validation'
import yup from '@/utils/yup'

type CommonChannelData = { message?: string }

export type SendRoutes = Array<{
  gid?: string
  channels?: Array<{
    name?:
      | 'sms'
      | 'viber'
      | 'whatsapp'
      | 'ok'
      | 'vk'
      | 'email'
      | 'push'
      | 'flashcall'
    timeout?: number
  }>
  isDefault?: boolean
}>

type ViberChannelData = {
  message?: string
  buttonName?: string
  buttonUrl?: string
  fileName?: string
}

type WhatsappChannelData = {
  message?: string
  fileName?: string
}

type СhannelsData = Record<
  string,
  CommonChannelData | ViberChannelData | WhatsappChannelData
>

// eslint-disable-next-line no-shadow
export enum ExtraPhonesType {
  SelectedGroups = 'selectedGroups',
  SelectedPhones = 'selectedPhones',
}

export const PhonesSourceTypeEnums = {
  ...PhonesType,
  ...PhonesTypeFile,
  ...ExtraPhonesType,
}

export type File = {
  name: string
  isLoading: boolean
  phonesCount: number
}

type Period = {
  sendImmediately: boolean[]
  startDate: string | undefined
  stopTime: boolean[]
  endTime?: string
  startTimeNextDay?: string
  timeout: number
  sendEvenly: boolean[]
  gap?: number
  gapTime?: string
  localtime: boolean[]
}

export type FormValues = {
  isSameMessages: boolean[]
  batchName?: string
  phonesSourceType: PhonesType | ExtraPhonesType
  // Для selfRegistered используется мультиселект с массивом номеров
  phonesSourceTextarea?: string | string[]
  blackListId: number[] | null
  // ignorePhoneFormat: boolean[]
  routeGroupId: string
  originator: string
  channels: СhannelsData
  rus: boolean[]
  message?: string
  phonesFile?: File
  startDateDatePicker?: string
  startTimeNextDay?: string
  startDateTimePicker?: string
  timeOutsms?: number
} & Period

const periodSection = 'batchCreate.form.period'
const phonesSourceValidation = 'batchCreate.form.phonesSource.validation'
const sendingSpeed = 'batchCreate.form.sendingSpeed'

export type RoutesType = SendFiltersForm['routes']
export type Route = RoutesType['string'][number]

// eslint-disable-next-line no-shadow
export enum Channel {
  Sms = 'sms',
  Viber = 'viber',
  Whatsapp = 'whatsapp',
  Ok = 'ok',
  Vk = 'vk',
  Email = 'email',
  Push = 'push',
}

export const channelsLimits = {
  sms: 10000,
  viber: 1000,
  whatsapp: 4096,
  ok: 2048,
  vk: 2048,
  email: 2048,
  push: 1024,
}

export const displayData = ref()

export const FILE_SIZE_KB = 1000 * 1024
export const FILE_EXT = '.jpg, .jpeg, .png, .gif, .bmp, .svg, .webp'

const { t } = i18n.global

const phonesSource = 'batchCreate.form.phonesSource'
const messageSection = 'batchCreate.form.message'

const textareaPhonesSourceOptions = computed(() => [
  {
    value: PhonesType.CommaSeparated,
    label: t(`${phonesSource}.select.options.commaSeparated`),
  },
  {
    value: PhonesType.LineSeparated,
    label: t(`${phonesSource}.select.options.lineSeparated`),
  },
  {
    value: PhonesType.LineSeparatedWithMessages,
    label: t(`${phonesSource}.select.options.lineSeparatedWithMessages`),
  },
])

const filePhonesSourceOptions = computed(() => [
  {
    value: PhonesTypeFile.LineSeparatedFile,
    label: t(`${phonesSource}.select.options.lineSeparatedFile`),
  },
  {
    value: PhonesTypeFile.LineSeparatedWithMessagesFile,
    label: t(`${phonesSource}.select.options.lineSeparatedFileWithMessages`),
  },
  {
    value: PhonesTypeFile.LineSeparatedWithParamsFile,
    label: t(`${phonesSource}.select.options.lineSeparatedWithParamsFile`),
  },
])

export const phonesSourceOptions = computed(() => [
  ...textareaPhonesSourceOptions.value,
  ...filePhonesSourceOptions.value,
])

// path: channels.sms.message
const getChannel = (path: string) => {
  const channel = path.split('.')[1] as `${Channel}`
  return channel
}

const getChannelLimit = (path: string) => {
  const channel = getChannel(path)
  const limit = channelsLimits[channel]

  return limit
}

const getCommonChannelMessageMaxLengthValidationSchema = (routes: RoutesType) =>
  yup.string().test(
    'channels.common.message.maxLength',
    ({ path }) => {
      const limit = getChannelLimit(path)

      return t('validation.string.max', { number: limit })
    },
    (value, context) => {
      if (!value) {
        return true
      }

      // @ts-expect-error Недоработка yup, не описано свойство from
      const routeGroupId = context.from[2].value.routeGroupId as string
      const routed = toRaw(routes)?.filter((item) => item.gid === routeGroupId)
      const route = routed.at(0).channels.map((item) => item.name)

      const path = context.path
      const channel = getChannel(path)

      if (route && !route?.includes(channel)) {
        return true
      }

      const limit = getChannelLimit(path)

      if (!limit) {
        return true
      }

      return value.length <= limit
    }
  )

const getCommonChannelValidationSchema = (
  routes: RoutesType
): yup.SchemaOf<CommonChannelData> =>
  yup.object({
    message: getCommonChannelMessageMaxLengthValidationSchema(routes).test(
      'channels.common.message.required',
      t('validation.required', {
        name: t(`${messageSection}.channels.common.textarea.label`),
      }),
      (value, context) => {
        // @ts-expect-error Недоработка yup, не описано свойство from
        const routeGroupId = context.from[2].value.routeGroupId as string
        const routed = toRaw(routes)?.filter(
          (item) => item.gid === routeGroupId
        )
        const route = routed.at(0).channels.map((item) => item.name)

        const path = context.path
        const channel = getChannel(path)

        if (route && !route.includes(channel)) {
          return true
        }

        return Boolean(value)
      }
    ),
  })

const getWhatsappChannelValidationSchema = (
  routes: RoutesType
): yup.SchemaOf<WhatsappChannelData> =>
  yup.object({
    message: getCommonChannelMessageMaxLengthValidationSchema(routes).test(
      'channels.smart.message.required',
      t('validation.required', {
        name: t(`${messageSection}.channels.common.textarea.label`),
      }),
      (value, context) => {
        // @ts-expect-error Недоработка yup, не описано свойство from
        const routeGroupId = context.from[2].value.routeGroupId as string

        const routed = toRaw(routes)?.filter(
          (item) => item.gid === routeGroupId
        )
        const route = routed.at(0).channels.map((item) => item.name)

        const path = context.path
        const channel = getChannel(path)

        if (route.length > 0 && !route.includes(channel)) {
          return true
        }

        // @ts-expect-error Недоработка yup, не описано свойство from
        const fileName = context.from[0].value.fileName as string

        if (fileName) {
          return true
        }

        return Boolean(value)
      }
    ),
    fileName: yup.string(),
  })

/*
  message обезательно, если нет fileName
  buttonName обезательно, если есть buttonUrl, если есть fileName
  buttonUrl обезательно, если есть buttonName, если есть fileName
  fileName обезательно, если нет message - мы добавим только одно условие, для message
*/

const getViberChannelValidationSchema = (
  routes: RoutesType
): yup.SchemaOf<ViberChannelData> =>
  getWhatsappChannelValidationSchema(routes).shape(
    {
      buttonName: yup.string().when(['buttonUrl', 'fileName'], {
        is: (buttonUrl: string, fileName: string) => buttonUrl || fileName,
        then: (schema) =>
          schema
            .max(30, t('validation.string.max', { number: 30 }))
            .requiredField(`${messageSection}.channels.viber.buttonName.label`),
      }),
      buttonUrl: yup.string().when(['buttonName', 'fileName'], {
        is: (buttonName: string, fileName: string) => buttonName || fileName,
        then: (schema) =>
          schema
            .max(1000, t('validation.string.max', { number: 1000 }))
            .matches(linkLinkRegex, t(`batchCreate.form.linkRegex`))
            .matches(linkHttpRegex, t(`batchCreate.form.linkHttp`))
            .requiredField(`${messageSection}.channels.viber.buttonUrl.label`),
      }),
    },
    [['buttonName', 'buttonUrl']]
  )

const maxPhonesSourceLength = 60000000

export const periodSchema = yup.object({
  sendImmediately: yup.array().of(yup.boolean()),
  startDate: yup.string().required(),
  stopTime: yup.array().of(yup.boolean()).required(),
  endTime: yup.string().when(['stopTime', 'startTimeNextDay'], {
    is: (stopTime: boolean[]) => checkboxToBoolean(stopTime),
    then: (schema) => schema.requiredField(`${periodSection}.endTime`),
    otherwise: (schema) => schema.optional(),
  }),
  startTimeNextDay: yup.string().optional(),
  timeout: yup.number().required(),
  sendEvenly: yup.array().of(yup.boolean()).required(),
  gap: yup.number().when('sendEvenly', {
    is: (value: boolean[]) => !checkboxToBoolean(value),
    then: (schema) => schema.required(`${periodSection}.gap`),
    otherwise: (schema) => schema.optional(),
  }),
  gapTime: yup.string().when(['endTime', 'startTimeNextDay', 'sendEvenly'], {
    is: (endTime: string, startTimeNextDay: string, sendEvenly: boolean[]) => {
      const isSendEvenly = checkboxToBoolean(sendEvenly)
      const isFilledTimes = endTime !== '' && startTimeNextDay !== ''

      if (isFilledTimes && isSendEvenly) {
        return false
      }

      if (isSendEvenly) {
        return true
      }

      return false
    },
    then: (schema) => schema.requiredField(`${sendingSpeed}.gapTime`),
    otherwise: (schema) => schema.optional(),
  }),
})

export const getValidationSchema = (routes: RoutesType) =>
  yup
    .object({
      batchName: yup
        .string()
        .test(
          'max',
          t('validation.string.max', { number: 255 }),
          (value) => !value || value.length <= 255
        ),
      phonesSourceTextarea: yup
        .mixed<string | string[]>()
        .when('phonesSourceType', {
          is: (value: string) =>
            textareaPhonesSourceOptions.value.some(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
              (option) => option.value === value
            ),
          then: (schema) => {
            return schema.test({
              test(value) {
                const phones = Array.isArray(value)
                  ? value.join(', ')
                  : value?.trim()

                if (!phones || phones.length === 0) {
                  return this.createError({
                    message: t('validation.required', {
                      name: t(`${phonesSource}.validation.textarea`),
                    }),
                  })
                }

                if (phones.length > maxPhonesSourceLength) {
                  return this.createError({
                    message: t('validation.string.max', {
                      number: maxPhonesSourceLength,
                    }),
                  })
                }

                return true
              },
            })
          },
        }),
      originator: yup
        .string()
        .min(
          originatorOptions.minLength,
          t('validation.string.min', { number: originatorOptions.minLength })
        )
        .max(
          originatorOptions.maxLength,
          t('validation.string.max', { number: originatorOptions.maxLength })
        )
        .matches(
          originatorOptions.regExp,
          t(`${messageSection}.originator.validation`)
        )
        .requiredField(`${messageSection}.originator.label`),
      channels: yup.object().when(['phonesSourceType'], {
        is: (type: string) => {
          return [
            PhonesType.LineSeparatedWithMessages,
            PhonesTypeFile.LineSeparatedWithMessagesFile,
          ].includes(type as PhonesTypeFile | PhonesType)
        },
        then: (schemaChannel) => schemaChannel.optional(),
        otherwise: (schemaChannel) =>
          schemaChannel.shape({
            sms: getCommonChannelValidationSchema(routes),
            viber: getViberChannelValidationSchema(routes),
            whatsapp: getWhatsappChannelValidationSchema(routes),
            ok: getCommonChannelValidationSchema(routes),
            vk: getCommonChannelValidationSchema(routes),
            email: getCommonChannelValidationSchema(routes),
            push: getCommonChannelValidationSchema(routes),
          }),
      }),
      phonesFile: yup.object().when(['phonesSourceType'], {
        is: (type: string) => {
          return [PhonesTypeFile.LineSeparatedFile].includes(
            type as PhonesTypeFile
          )
        },
        then: (schema) => {
          return schema
            .shape({
              name: yup.string().when('isLoading', {
                is: (isLoading: boolean) => isLoading,
                then: (schemaName) =>
                  schemaName.required(
                    t(`${phonesSourceValidation}.file.loading`)
                  ),
                otherwise: (schemaName) =>
                  schemaName.required(
                    t(`${phonesSourceValidation}.file.required`)
                  ),
              }),
              isLoading: yup.boolean(),
            })
            .required()
        },
      }),
    })
    .concat(periodSchema)
