import { computed } from 'vue'

import type { BatchChannelTemplate } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'

export type FormValues = {
  name: string
  originator: string
  startDate: string
  isLocalTime: boolean[]
  suspend: boolean[]
  endTime: string
  startTimeNextDay: string
  gap: number
  timeout: number
  rus: boolean[]
  channels?: BatchChannelTemplate
  message?: string
}

// timeout в минутах
export const getTimeoutOptions = computed(() => {
  const t = useComponentI18n('units')

  const result = [
    {
      value: 0, // Значение по умолчанию
      label: `24 ${t('hour', 24)}`,
    },
    {
      value: 30,
      label: `30 ${t('minute', 30)}`,
    },
  ]

  for (let i = 1; i < 13; i++) {
    result.push({
      value: i * 60,
      label: `${i} ${t('hour', i)}`,
    })
  }

  return result
})

const gapValuesInSeconds = [
  0.05, 0.1, 0.2, 0.5, 1, 2, 3, 6, 10, 20, 30, 60, 120, 300, 600,
]

// gap в секундах
export const getGapOptions = (gapValue?: number) => {
  const t = useComponentI18n('shortUnits')

  const result = gapValuesInSeconds.map((value) => {
    const canUseMinutes = value >= 60

    const units = canUseMinutes ? 'minute' : 'second'
    const valueInLabel = canUseMinutes ? value / 60 : value

    return {
      value,
      label: `${valueInLabel} ${t(units)}`,
    }
  })

  if (gapValue && !gapValuesInSeconds.includes(gapValue)) {
    result.unshift({
      value: gapValue,
      label: `${gapValue} ${t('second')}`,
    })
  }

  return result
}

// speed сообщений в минуту
export const getSpeedOptions = (gapValue?: number) => {
  const t = useComponentI18n('batches.edit.form')

  return computed(() => {
    const result = gapValuesInSeconds.map((value) => {
      const valueInLabel = 60 / value

      return {
        value,
        label: `${valueInLabel} ${t('speedUnits')}`,
      }
    })

    if (gapValue && !gapValuesInSeconds.includes(gapValue)) {
      const extraValueInLabel = parseFloat((60 / gapValue).toFixed(2))

      result.unshift({
        value: gapValue,
        label: `${extraValueInLabel} ${t('speedUnits')}`,
      })
    }

    return result
  })
}
